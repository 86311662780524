<template>
  <div>
    <h3
      class="text-2xl text-dokBlue-ultra font-semibold border-0 border-b-2 border-solid pb-2 mb-4"
    >
      <i class="fas fa-notes-medical"></i> Antécédents médicaux
    </h3>
    <div class="mb-5 w-full flex justify-between hidden">
      <h5>Avez-vous souffert ou souffrez-vous :</h5>
      <p class="text-xs text-gray-500">
        (Cliquer sur <i class="fa fa-check"></i> pour sauvegarder)
      </p>
    </div>
    <div class="flex flex-col w-full border-0  py-5">
      <div class="flex justify-between">
        <h4 class="text-xl text-ngray font-semibold border-0 pb-2 mb-2">
          Avez-vous souffert ou soufferez-vous d'autres maladies chroniques ?
        </h4>
      </div>
    </div>
    <table class="table-auto w-full ml-auto " :class="animationClazz">
      <thead>
        <tr>
          <th
            class="double-line px-4 py-4 bg-dokBlue-ultra text-white border-r border-solid  align-middle text-center "
          >
            Selectionnez une pathologie
          </th>
          <th
            class="px-4 py-2 bg-dokBlue-ultra text-white text-center  border-b border-solid"
          >
            Depuis quand vous en souffrez ?
          </th>
          <th
            class="px-4 py-2 bg-dokBlue-ultra text-white text-center  border-b border-solid"
          >
            Prenez vous un traitement ?
          </th>
          <th
            class="px-4 py-4 bg-dokBlue-ultra text-white border-r border-solid align-middle border-l text-center"
          >
            selectionnez le/les medicaments ?
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <antecedent-medical
          :patient="patient"
          v-for="(item, key) in antecedentMedicaux"
          :key="item._id"
          :antecedent="item"
          v-on:savedAntMedic="savedAntMedical"
          classz="flex justify-end"
        ></antecedent-medical>
        <tr>
          <td>
            <multiselect
              :searchable="true"
              :multiple="true"
              :max="1"
              :taggable="true"
              @tag="addTag"
              :options="notRequiredAntecedents"
              @search-change="onSearchAntcedentMedicals"
              v-model="selectedAntmedics"
              track-by="name"
              label="name"
              placeholder="Choisissez une maladie"
            >
              <template slot="noResult">Il n'y a aucun résultat</template>
            </multiselect>
          </td>
          <td>
            <datepicker
              v-model="startTreatmentDate"
              placeholder="Date"
              :language="dateLangFR"
              class=""
              input-class="bg-white  appearance-none border border-solid inline-block py-2 pl-3 pr-8 rounded leading-none placeholder-gray-500 w-full border-dokBlue-ultra"
            ></datepicker>
          </td>
          <td class="text-center">
            <toggle-button
              v-model="followTreatment"
              :sync="true"
              :labels="{ checked: 'Oui', unchecked: 'No' }"
            ></toggle-button>
          </td>
          <td>
            <!-- <input
                type="text"
                v-model="moreInfoAntecedentMedical"
                class="bg-white text-dokBlue-ultra appearance-none border border-solid border-dokBlue-ultra inline-block py-2 pl-3 pr-8 rounded leading-none placeholder-gray-500 w-full"
                placeholder="Plus d'info"
              /> -->
            <input-medicaments
              :set-item-parent="setMedicament"
              v-model="moreInfoAntecedentMedical"
              :classes="error ? 'border-red-700' : ''"
            ></input-medicaments>
          </td>
          <td>
            <button
              v-if="selectedAntmedics.length > 0"
              @click="dispatchAction"
              class="bg-transparent border border-dokBlue-ultra mt-1 py-2 px-2 rounded-cu text-dokBlue-ultra cursor-pointer"
            >
              <i :class="'fas fa-' + saveBtn.icon"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
const inputMedicaments = () =>
  import(
    "@/views/dashboard/consultations/components/prescriptions/layouts/inputMedicaments.vue"
  );
const AntecedentMedical = () => import("./antecedent-medical");
const toggleInput = () => import("@/views/global-components/toggle");
import { findIndex, remove } from "lodash";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import { EventBus } from "@/event-bus";

export default {
  props: ["patient"],
  components: {
    inputMedicaments,
    toggleInput,
    AntecedentMedical,
    vSelect,
    Datepicker
  },
  data() {
    return {
      followTreatment: false,
      dateLangFR: fr,
      showNotRequired: false,
      showAntecedentMedicalBlock: true,
      antecedentMedicaux: [],
      searchAntecedentMedicaux: [],
      selectedAntmedics: [],
      selectedAntmedic: null,
      moreInfoAntecedentMedical: "",
      startTreatmentDate: "",
      error: "",
      isLoading: false,
      notRequiredAntecedents: [],
      animationClazz: "",
      saveBtn: {
        color: "nblue",
        icon: "plus"
      }
    };
  },
  mounted() {
    var el = this;
    this.$store
      .dispatch("healthbook/GET_ANTECEDENT_MEDICAUX_LIST", {
        patientId: this.patient._id
      })
      .then(function(response) {
        el.antecedentMedicaux = response.data.list;
        // //console.log(response.data.list, "skmdjfdksljdskljfdksljfdksldsdskl", el.antecedentMedicaux)
      });
    this.onSearchAntcedentMedicals("");
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag
      };
      this.selectedAntmedics.push(tag);
    },
    setMedicament(data) {
      this.moreInfoAntecedentMedical = data.name;

      this.$forceUpdate();
      EventBus.$emit("closeMedicamentInput", { name: data.name });
    },

    savedAntMedical(antMed) {
      if (antMed.isChecked) {
        const index = findIndex(this.antecedentMedicaux, function(ob) {
          return ob._id == antMed._id;
        });
        if (index == -1) {
          this.antecedentMedicaux.push(antMed);
        }
      } else if (!antMed.isChecked) {
        remove(this.antecedentMedicaux, function(ob) {
          return ob._id == antMed._id;
        });
      }
      this.$forceUpdate();
    },
    async dispatchAction(evt) {
      // //console.log(this.selectedAntmedic, this.moreInfoAntecedentMedical);
      // return false;
      if (this.selectedAntmedics.length < 1) return;

      this.selectedAntmedic = this.selectedAntmedics[0];
      this.selectedAntmedic.isChecked = true;
      const el = this;
      this.$store.dispatch("healthbook/SAVE_PATIENT_ANTECEDENT_MEDICAL", {
        patientId: this.patient._id,
        data: {
          name: this.selectedAntmedic.name,
          refId: this.selectedAntmedic._id,
          moreInfo: this.moreInfoAntecedentMedical,
          startTreatmentDate: this.startTreatmentDate,
          followTreatment: this.followTreatment
        },
        onData: function() {
          const antcedent = {
            // antecedent: Object.assign({}, el.selectedAntmedic),
            name: el.selectedAntmedic.name,
            isChecked: true,
            startTreatmentDate: el.startTreatmentDate,
            followTreatment: el.followTreatment,
            moreInfo: el.moreInfoAntecedentMedical
          };
          //console.log("sdkljfsdkmlfjsdklmjdfsklmj", antcedent);
          el.antecedentMedicaux.push(antcedent);
          el.selectedAntmedic = null;
          el.selectedAntmedics = [];

          el.moreInfoAntecedentMedical = "";
          el.startTreatmentDate = null;
          el.followTreatment = false;

          el.$forceUpdate();
          // el.animationClazz = 'animation-clazz';
          // setTimeout(function(){
          //   el.animationClazz = '';
          // }, '3000')
          el.onSearchAntcedentMedicals("");
        }
      });
      this.$forceUpdate();
    },

    async onSearchAntcedentMedicals(search) {
      this.isLoading = true;
      const data = await this.$store.dispatch(
        "healthbook/SEARCH_ANTECEDENT_FAMILIAUX_LIST",
        {
          params: {
            patientId: this.patient._id,
            term: search,
            limit: 10
          }
        }
      );

      if (data.ok) {
        this.notRequiredAntecedents = data.data;
        this.isLoading = false;
      }
    }
  }
};
</script>
<style>
.animation-clazz .ant-medic-line:last-child {
  transition: all 3s ease;
  background: #eee;
  padding: 5px;
  /*border: 2px solid #AAA;*/
}
</style>
